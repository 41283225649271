import { Box, Button, useTheme } from '@mui/material'
import { LicensesListLicensedEstablishmentPage } from './LicensesListLicensedEstablishmentPage'
import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { useSetContactUsRequestType } from 'stores/useSetContactUsRequestType'
import { useNavigate } from 'react-router-dom'

export const LicensesLicensedEstablishmentPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const updateLicenses = useSetContactUsRequestType(
    (state) => state.updateLicenses
  )

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Licenses"
            isSecondary={true}
            headerBgColor="white"
            actionButton={
              <Button
                onClick={async () => {
                  updateLicenses()
                  await navigate('/ContactUs')
                }}
                variant="contained"
              >
                Upload Licenses
              </Button>
            }
          />
        </Box>
      }
    >
      <Box sx={{ backgroundColor: theme.palette.secondary.light }}>
        <Box
          px={{ xs: '16px', sm: '41px' }}
          pt={{ xs: '21px', sm: '32px' }}
          pb={'1%'}
        >
          <LicensesListLicensedEstablishmentPage />
        </Box>
      </Box>
    </Page>
  )
}
