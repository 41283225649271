import { Box, Button, Stack } from '@mui/material'
import { FormFieldErrorMessage } from 'components/FormFieldMessage/FormFieldErrorMessage'
import { type ChangeEvent } from 'react'
import { useState, useRef, useEffect } from 'react'

interface AttachFilesProps {
  files: Array<{ file: File; base64: string }> | undefined
  onFilesChange: (files: Array<{ file: File; base64: string }>) => void
}

export const AttachFiles: React.FC<AttachFilesProps> = ({
  onFilesChange,
}: AttachFilesProps) => {
  const [files, setFiles] = useState<
    Array<{
      file: File
      base64: string
      name: string
      size: number
      type: string
    }>
  >([])

  const [fileSizeError, setFileSizeError] = useState<string | null>(null)
  const [fileSizeInfo, setFileSizeInfo] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  // Helper to generate a unique key for each file
  const generateFileKey = (file: File) => `${file.name}-${file.lastModified}`

  // Helper to convert file to base64 string
  const fileToBase64 = async (file: File): Promise<string> => {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1] // Remove the prefix
        resolve(base64String)
      }
      reader.onerror = () => reject(new Error('File reading error'))
    })
  }

  const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB in bytes
  const MAX_TOTAL_SIZE = 20 * 1024 * 1024 // 20MB in bytes

  // calculate the total size of files and update the UI
  const calculateTotalSize = (files: Array<{ file: File }>) => {
    const totalSize = files.reduce((total, file) => total + file.file.size, 0)
    const totalSizeMB = Math.round((totalSize / (1024 * 1024)) * 100) / 100

    // Update the file size display only if there are files
    if (files.length > 0) {
      setFileSizeInfo(`, ${totalSizeMB}MB of 20MB limit`)
    } else {
      setFileSizeInfo('')
    }

    return totalSize
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setFileSizeError(null)

    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files)

      // filter out individual files that are too large
      const tooLargeFiles = selectedFiles.filter(
        (file) => file.size > MAX_FILE_SIZE
      )

      if (tooLargeFiles.length > 0) {
        setFileSizeError(
          `The following files exceed the 10MB limit: ${tooLargeFiles
            .map((file) => file.name)
            .join(', ')}`
        )
      }

      // filter out duplicate files and those that are too large
      const newFiles = selectedFiles.filter(
        (file) =>
          !files.some(
            (existingFile) =>
              generateFileKey(existingFile.file) === generateFileKey(file)
          ) && file.size <= MAX_FILE_SIZE
      )

      // calculate total size of existing files + new files
      const currentTotalSize = calculateTotalSize(files)
      const newFilesTotalSize = newFiles.reduce(
        (total, file) => total + file.size,
        0
      )
      const totalSize = currentTotalSize + newFilesTotalSize

      // check to see if total size is over 20MB limit
      if (totalSize > MAX_TOTAL_SIZE) {
        setFileSizeError(
          `${newFiles.length} files not added. The total file size would exceed the 20MB limit.`
        )
        return
      }

      // add files if no error
      if (newFiles.length > 0) {
        const newFilesWithBase64 = await Promise.all(
          newFiles.map(async (file) => {
            const base64 = await fileToBase64(file)
            return {
              file, // Include the full file object
              base64,
              name: file.name,
              size: file.size,
              type: file.type, // Include the other properties
            }
          })
        )

        setFiles((prevFiles) => {
          const updatedFiles = [...prevFiles, ...newFilesWithBase64]
          onFilesChange(updatedFiles) // Send updated files with the 'file' object
          calculateTotalSize(updatedFiles)
          return updatedFiles
        })
      }
    }
  }

  const handleRemoveFile = (fileToRemove: File) => {
    setFileSizeError(null)
    const updatedFiles = files.filter(
      (file) => generateFileKey(file.file) !== generateFileKey(fileToRemove)
    )

    // recalculate the total size after file removal
    calculateTotalSize(updatedFiles)

    setFiles(updatedFiles)
    onFilesChange(updatedFiles)
  }

  // clear value when all files are removed
  useEffect(() => {
    if (files.length === 0 && fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }, [files])

  const renderFilePreview = (file: File) => {
    if (file.type.startsWith('image/')) {
      const objectURL = URL.createObjectURL(file)
      return (
        <img
          src={objectURL}
          alt={file.name}
          style={{ width: 75, height: 75, objectFit: 'cover' }}
        />
      )
    }
    return (
      <span
        aria-label="file-icon"
        style={{
          fontSize: 50,
          width: 75,
          height: 75,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        📄
      </span>
    )
  }

  return (
    <Box>
      <Box style={{ marginBottom: 20 }}>
        <input
          type="file"
          multiple
          onChange={async (e) => await handleFileChange(e)}
          accept="*/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <label
          htmlFor="file-upload"
          style={{
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{ width: '150px' }}
            variant="contained"
            color="info"
            onClick={() => fileInputRef.current?.click()}
          >
            Attach Files
          </Button>
          <span style={{ marginLeft: 20 }}>
            {files.length} file{files.length !== 1 ? 's' : ''} selected
            {fileSizeInfo}
          </span>
        </label>
      </Box>

      <Box mt={3} mb={3}>
        {fileSizeError && <FormFieldErrorMessage message={fileSizeError} />}
      </Box>

      <Box>
        {files.map(({ file, name }) => (
          <Box
            key={generateFileKey(file)} // Use the file's unique identifier
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <Stack direction="column" spacing={2}>
              <Button
                sx={{ width: '150px' }}
                onClick={() => handleRemoveFile(file)} // Pass the full 'file' object
                style={{
                  marginRight: 10,
                }}
              >
                Remove
              </Button>
            </Stack>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 20,
              }}
            >
              {renderFilePreview(file)}
              <span style={{ marginLeft: 10 }}>{name}</span>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
