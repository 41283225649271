import { Box, Grid, Link, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { colorPrimitives } from 'components/Theme'
import { Link as RouterLink } from 'react-router-dom'

export const RelatedAccountsMobile = ({
  corporateAccountName,
  organizationName,
  leId,
}: {
  corporateAccountName: string
  organizationName?: string
  leId: number
}) => {
  return (
    <Box>
      <Grid container gap={2} display={'flex'} justifyContent={'space-between'}>
        <Grid item className="pt-3" xs={8}>
          <Typography variant="h3" pb={2}>
            Related Accounts
          </Typography>
        </Grid>
      </Grid>
      <Box
        border={{ md: `1px solid ${grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container className="flex flex-row flex-wrap">
          <Grid item xs={12}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Corporate Accounts
            </Typography>
            <Typography variant="body1" pb={2}>
              {corporateAccountName || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Organization
            </Typography>
            {organizationName ? (
              <Typography variant="body1" pb={2}>
                {organizationName}
              </Typography>
            ) : (
              <Link
                to={`/LicensedEstablishments/${leId}/AssignOrganization`}
                underline="none"
                component={RouterLink}
                color={colorPrimitives.redGaming}
              >
                <Typography>Assign Organization</Typography>
              </Link>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
