import { useMutation } from '@tanstack/react-query'
import { useTypedApiClient } from '../useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'

export const usePostLeUserCa = ({
  onError,
  onSuccess,
}: {
  onSuccess?: () => Promise<void>
  onError?: (error: ApiError) => Promise<void>
}) => {
  const post = useTypedApiClient({
    path: '/le-connect/licensed-establishments/users/corporate-accounts',
    method: 'post',
  })

  return useMutation({
    mutationKey: [
      '/le-connect/licensed-establishments/users/corporate-accounts',
    ],
    mutationFn: async ({
      corporateAccountId,
      licensedEstablishmentUserId,
      allowed,
    }: {
      licensedEstablishmentUserId: number
      corporateAccountId: number
      allowed: boolean
    }) => {
      const { data } = await post({
        corporateAccountId,
        licensedEstablishmentUserId,
        allowed,
      })
      return data
    },
    onSuccess,
    onError,
  })
}
