import { useState, useCallback, useMemo } from 'react'
import {
  DataGrid,
  type DataGridProps,
  gridClasses,
  type GridColDef,
  type GridRenderCellParams,
  type GridRowClassNameParams,
  type GridRowParams,
} from '@mui/x-data-grid'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { cloneDeep } from 'lodash'
import { backgroundColorDepth, type TreeDataGridRow } from './utils'
import { ExpandableCell, iconMargin, iconWidth } from './ExpandableCell'
import { useLayout } from 'hooks/useLayout'
import { IndentedCell } from './IndentedCell'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { colorPrimitives } from 'components/Theme'

const StyledDataGrid = styled(DataGrid<TreeDataGridRow>)(() => ({
  '& .MuiDataGrid-cell': {
    color: '#2C2C27',
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    padding: 0,
  },
  ...backgroundColorDepth.reduce(
    (accumulator, current, index) => ({
      ...accumulator,
      [`& .${gridClasses.row}.depth-${index}`]: { backgroundColor: current },
      [`& .${gridClasses.row}.depth-${index}:hover`]: {
        backgroundColor: colorPrimitives.lightGray,
      },
    }),
    {}
  ),
}))

const defaultCellRenderer = (params: GridRenderCellParams, col: GridColDef) =>
  col.renderCell ? (
    col.renderCell(params)
  ) : (
    <Typography>{params.row[col.field]}</Typography>
  )

const createFirstCellRenderer =
  (col: GridColDef, onToggleExpand: (row: TreeDataGridRow) => void) =>
  // eslint-disable-next-line react/display-name
  (params: GridRenderCellParams) =>
    !!params.row.children && params.row.children.length > 0 ? (
      <IndentedCell depth={params.row.parentIndexPath.length}>
        <ExpandableCell
          expanded={!!params.row.expanded}
          toggleExpandHandler={() => onToggleExpand(params.row)}
          rowId={params.row.rowId}
        >
          {defaultCellRenderer(params, col)}
        </ExpandableCell>
      </IndentedCell>
    ) : (
      <IndentedCell
        depth={params.row.parentIndexPath.length}
        extraIndentation={iconWidth + iconMargin}
      >
        {defaultCellRenderer(params, col)}
      </IndentedCell>
    )

const getColumns = (
  columns: ReadonlyArray<GridColDef<TreeDataGridRow>>,
  onToggleExpand: (row: TreeDataGridRow) => void
): GridColDef[] => {
  return columns.map((col, idx) => {
    const commonConfig = {
      ...col,
      sortable: false,
    }

    if (idx === 0) {
      return {
        ...commonConfig,
        renderCell: createFirstCellRenderer(col, onToggleExpand),
      }
    } else {
      return commonConfig
    }
  })
}

const getRowsRecursively = (
  rows: TreeDataGridRow[],
  index: number = 0,
  parentIndexPath: number[] = [],
  expandAll: boolean = false
): DataGridProps<TreeDataGridRow>['rows'] => {
  if (index >= rows.length) {
    return []
  }

  const currentRow = rows[index]
  const rowWithIndexPath = {
    ...currentRow,
    parentIndexPath,
    index,
  }

  const childRows =
    (currentRow.expanded === true || expandAll) &&
    currentRow.children &&
    currentRow.children.length > 0
      ? getRowsRecursively(
          currentRow.children,
          0,
          [...parentIndexPath, index],
          expandAll
        )
      : []

  return [
    rowWithIndexPath,
    ...childRows,
    ...getRowsRecursively(rows, index + 1, parentIndexPath, expandAll),
  ]
}

const toggleRowExpand = (row: TreeDataGridRow) => {
  const newRow: TreeDataGridRow = { ...row }
  newRow.expanded = !newRow.expanded
  return newRow
}

const findParentRow = (
  rows: TreeDataGridRow[] | undefined,
  parentIndexPath: number[],
  row?: TreeDataGridRow
): TreeDataGridRow | undefined => {
  if (parentIndexPath.length > 0 && !!rows) {
    return findParentRow(
      rows[parentIndexPath[0]].children,
      parentIndexPath.slice(1),
      rows[parentIndexPath[0]]
    )
  } else {
    return row
  }
}

const getRowsExpandHandler = (
  rows: TreeDataGridRow[],
  row: TreeDataGridRow
): TreeDataGridRow[] => {
  if (row.children && row.children.length > 0) {
    const newRows = cloneDeep(rows)
    if (row.parentIndexPath.length > 0) {
      const parentRow = findParentRow(newRows, row.parentIndexPath)
      if (parentRow?.children && parentRow?.children.length > 0) {
        parentRow.children[row.index] = toggleRowExpand(
          parentRow.children[row.index]
        )
      }
    } else {
      newRows[row.index] = toggleRowExpand(newRows[row.index])
    }
    return newRows
  }
  return rows
}

const getRowClassName = (dataGridParams: GridRowClassNameParams) =>
  `depth-${dataGridParams.row.parentIndexPath.length}`

interface TreeDataGridProps
  extends Omit<DataGridProps<TreeDataGridRow>, 'rows'> {
  rows: TreeDataGridRow[]
  desktopHeight?: string
  mobileColumns?: Array<GridColDef<TreeDataGridRow>>
  recalculateRows?: boolean
}

export const TreeDataGrid = ({
  rows,
  columns,
  mobileColumns,
  desktopHeight = '100%',
  recalculateRows = false,
  ...dataGridProps
}: TreeDataGridProps) => {
  const { isMobile } = useLayout()
  const [treeRows, setTreeRows] = useState<TreeDataGridRow[]>(rows)

  const onToggleExpand = useCallback(
    (row: TreeDataGridRow) => {
      setTreeRows(getRowsExpandHandler(treeRows, row))
    },
    [treeRows, setTreeRows]
  )

  const staticRows = getRowsRecursively(rows, 0, [], isMobile)

  const onRowClick = useCallback(
    (params: GridRowParams) => {
      if (params.row.children && params.row.children.length > 0) {
        onToggleExpand(params.row)
      }
    },
    [onToggleExpand]
  )

  const recursiveRows = useMemo(
    () => getRowsRecursively(treeRows, 0, []),
    [treeRows]
  )

  if (rows.length === 0) {
    return <NoDataIndicator />
  }

  if (isMobile && mobileColumns) {
    return (
      <StyledDataGrid
        disableRowSelectionOnClick
        disableColumnMenu
        rows={recalculateRows ? staticRows : recursiveRows}
        columns={getColumns(mobileColumns, onToggleExpand)}
        getRowClassName={getRowClassName}
        getRowId={(row) => row.rowId}
        hideFooter
        getRowHeight={() => 'auto'}
        autoHeight
        onRowClick={onRowClick}
        {...dataGridProps}
      />
    )
  }

  return (
    <StyledDataGrid
      disableRowSelectionOnClick
      disableColumnMenu
      rows={recalculateRows ? staticRows : recursiveRows}
      columns={getColumns(columns, onToggleExpand)}
      getRowClassName={getRowClassName}
      getRowId={(row) => row.rowId}
      hideFooter
      rowHeight={64}
      sx={{ overflowY: 'auto', height: desktopHeight, width: '100%' }}
      onRowClick={onRowClick}
      {...dataGridProps}
    />
  )
}
