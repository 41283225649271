import Box from '@mui/material/Box'
import { type components } from 'api/playerPayback/api'
import { Button, Divider, Typography } from '@mui/material'
import { AddressDetailsMobile } from './AddressDetails/AddressDetailsMobile'
import { ScheduleDetails } from './ScheduleDetails/ScheduleDetails'
import { BusinessDetailsMobile } from './BusinessDetails/BusinessDetailsMobile'
import { ContactDetailsMobile } from './ContactDetails/ContactDetailsMobile'
import { ThumbnailDetails } from './ThumbnailDetails/ThumbnailDetails'
import { RelatedAccountsMobile } from './RelatedAccounts/RelatedAccountsMobile'
import { LinksDetailsDesktop } from './LinkDetails/LinkDetailsDesktop'
import { useNavigate } from 'react-router-dom'
import { useSetContactUsRequestType } from 'stores/useSetContactUsRequestType'

type licensedEstablishmentType =
  components['schemas']['FullLicensedEstablishmentDTO']
interface LicensedEstablishmentDetailsProps {
  licensedEstablishment: licensedEstablishmentType
}

export const LicensedEstablishmentDetailsMobile = ({
  licensedEstablishment,
}: LicensedEstablishmentDetailsProps) => {
  const navigate = useNavigate()
  const updateHours = useSetContactUsRequestType((state) => state.updateHours)

  return (
    <Box>
      <Box>
        <Box className="flex lg:justify-end" sx={{ mb: 1 }}>
          <Button
            variant="contained"
            onClick={async () => {
              updateHours()
              await navigate('/ContactUs')
            }}
          >
            <Typography>Update Hours</Typography>
          </Button>
        </Box>
      </Box>
      <BusinessDetailsMobile
        licensedEstablishment={licensedEstablishment}
      ></BusinessDetailsMobile>

      <Divider className="pt-4" />
      <Box className="pt-4">
        <RelatedAccountsMobile
          corporateAccountName={String(
            licensedEstablishment.corporateAccount?.name
          )}
          organizationName={licensedEstablishment.organization?.name}
          leId={licensedEstablishment.id}
        />
      </Box>

      <Divider className="pt-4" />
      <Box className="pt-4">
        <ContactDetailsMobile
          licensedEstablishment={licensedEstablishment}
        ></ContactDetailsMobile>
      </Box>

      <Divider className="pt-3" />
      <Box className="pt-4">
        <AddressDetailsMobile
          licensedEstablishment={licensedEstablishment}
        ></AddressDetailsMobile>
      </Box>

      <Divider className="pt-3" />
      <Box className="pt-4">
        <ScheduleDetails
          licensedEstablishment={licensedEstablishment}
        ></ScheduleDetails>
      </Box>

      <Divider className="pt-3" />
      <Box className="pt-4">
        <ThumbnailDetails
          licensedEstablishment={licensedEstablishment}
        ></ThumbnailDetails>
      </Box>

      <Divider className="pt-3" />
      <Box className="pt-4">
        <LinksDetailsDesktop licensedEstablishment={licensedEstablishment} />
      </Box>
    </Box>
  )
}
