import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  type SelectChangeEvent,
} from '@mui/material'
import { useLayout } from 'hooks/useLayout'
import { useState } from 'react'

export const FlexPayTypes = [
  'AchDeposit',
  'Activity',
  'Reconciliation',
  'Reimbursement',
]

interface SelectProps {
  onSelect: (typ: string) => void
  type: any
  disabledValue: boolean
}

export const SelectFlexPayType = ({
  onSelect,
  type,
  disabledValue,
}: SelectProps) => {
  const isMobile = useLayout()
  const [selectedValue, setSelectedValue] = useState<string>()

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedValue(event.target.value)
    onSelect(event.target.value)
  }

  return (
    <FormControl fullWidth>
      <Select
        value={(type || selectedValue) ?? ''}
        disabled={disabledValue}
        onChange={handleChange}
        sx={{ width: isMobile.isMobile ? '90vw' : '500px' }}
        displayEmpty
        renderValue={(selectedItem) => {
          if (!selectedItem) {
            return <em>Select Type</em>
          }

          return <Typography>{selectedItem}</Typography>
        }}
        variant="outlined"
        required={false}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {FlexPayTypes.map((type) => (
          <MenuItem
            value={type}
            key={type}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
