import { useQueries } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

const route = '/le-connect/organizations/corporate-accounts'

export const useGetOrganizationsByCorporateAccountIds = ({
  corporateAccountIds,
  enabled = true,
}: {
  corporateAccountIds: number[]
  enabled?: boolean
}) => {
  const get = useTypedApiClient({
    path: route,
    method: 'get',
  })

  return useQueries({
    queries: corporateAccountIds.map((corporateAccountId) => ({
      queryKey: ['organization', corporateAccountId],
      queryFn: async () => {
        const queryResult = await get({
          corporateAccountId: [corporateAccountId],
        })
        return queryResult.data
      },
      enabled,
    })),
  })
}
