import { format } from 'date-fns'
import { useApiQuery } from 'hooks/useApiQuery'

interface UseGetFlexPayStatementsByIdParams {
  licensedEstablishmentId: number | undefined
  currentPage: number
  pageSize: number
  type: any
  startDate: Date
  endDate: Date
  enabled?: boolean
}

const formatDate = (d: Date) => format(d, 'yyyy-MM-dd')

export const useGetFlexPayStatements = ({
  licensedEstablishmentId,
  currentPage,
  pageSize,
  type,
  startDate,
  endDate,
  enabled = true,
}: UseGetFlexPayStatementsByIdParams) => {
  return useApiQuery({
    path: '/le-connect/flexpaystatements/{licensedEstablishmentId}/{currentPage}/{pageSize}',
    method: 'get',
    queryKey: [
      'flexpaystatements',
      licensedEstablishmentId,
      currentPage,
      pageSize,
      type,
      startDate,
      endDate,
    ],
    queryArgs: {
      licensedEstablishmentId,
      currentPage,
      pageSize,
      type,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    },
    enabled,
  })
}
