import { Box, Button, Typography } from '@mui/material'
import { JJNewsHeader } from './JJNewsHeader'

export const ResourcesPage = () => {
  return (
    <JJNewsHeader currentTab={'/JJNews/ResourcesPage'}>
      <Typography variant="h6">
        Want to take your gaming to the next level?
      </Typography>
      <Typography variant="h6">
        Click the button below for social media graphics, printable signage, and
        expert tips.
      </Typography>
      <Box className="mt-3">
        <Button
          variant="contained"
          component="a"
          href={
            'https://www.jjventures.com/il-gaming/resources-tips-and-tricks/'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography>Gaming Resources</Typography>
        </Button>
      </Box>
    </JJNewsHeader>
  )
}
