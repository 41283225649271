import { Box, Typography } from '@mui/material'
import { PageHeader } from 'components/Shared/PageHeader'
import { formatLEUserType } from 'utils/util'
import { LEConnectUserDetailPageTabs } from './LEConnectUserDetailPageTabs'
import { type useUserData } from 'hooks/api/useUserData'
import { isNil } from 'lodash'

export const LeConnectUserDetailsHeader = ({
  user,
  formattedUpdatedAt,
  formattedLastAccessed,
  userName,
  currentTab,
}: {
  user: ReturnType<typeof useUserData>['user']
  formattedUpdatedAt: string | null
  formattedLastAccessed: string | null
  userName: string | null
  currentTab: 'Overview'
}) => {
  if (isNil(user) || isNil(userName)) {
    return null
  }

  return (
    <PageHeader
      title={userName}
      isSecondary={true}
      backPath="/LicensedEstablishmentUsers/LEConnectUsers"
      backText="J&J Connect Users"
      customSubTitle={
        <Box className="flex flex-row items-center self-stretch gap-4 mb-4">
          <Typography
            variant="body-1"
            fontWeight="bold"
            className="uppercase text-secondary"
          >
            {formatLEUserType(
              user?.licensedEstablishments?.[0]
                ? user?.licensedEstablishments[0].type
                : ''
            )}
          </Typography>
          {formattedUpdatedAt && (
            <>
              <Typography color={'text.secondary'}>|</Typography>
              <Typography variant="body-1" color={'text.secondary'}>
                {formattedUpdatedAt}
              </Typography>
            </>
          )}

          {formattedLastAccessed && (
            <>
              <Typography color={'text.secondary'}>|</Typography>
              <Typography variant="body-1" color={'text.secondary'}>
                {formattedLastAccessed}
              </Typography>
            </>
          )}
        </Box>
      }
      tabs={
        <LEConnectUserDetailPageTabs
          currentTab={currentTab}
          leuserId={user.id}
        />
      }
    />
  )
}
