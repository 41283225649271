import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation } from '@tanstack/react-query'
import { type components } from 'api/playerPayback/api'
import { type ContactUsEmailRequestType } from 'src/types/api'

export const usePostJJConnectUserContactUsEmail = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void | Promise<void>
  onError: () => void
}) => {
  const post = useTypedApiClient({
    path: '/le-connect/licensed-establishments/users/leUser-contact-us',
    method: 'post',
  })

  return useMutation({
    mutationFn: async (data: {
      licensedEstablishmentUserId: number
      licensedEstablishmentId: number
      requestType: ContactUsEmailRequestType
      body: string
      files?: Array<components['schemas']['FileDetail']>
    }) => {
      return await post({
        licensedEstablishmentUserId: data.licensedEstablishmentUserId,
        licensedEstablishmentId: data.licensedEstablishmentId,
        contactUsEmailRequestType: data.requestType,
        body: data.body,
        files: data.files,
      })
    },
    onSuccess,
    onError,
  })
}
