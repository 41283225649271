import { Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

export const JJNewsPageTabs = ({ currentTab }: { currentTab: any }) => {
  return (
    <Tabs value={currentTab}>
      <Tab
        label="Newsletters"
        value={`/JJNews/NewslettersPage`}
        to={`/JJNews/Newsletters`}
        component={Link}
      />
      <Tab
        label="News"
        value={`/JJNews/NewsPage`}
        to={`/JJNews/News`}
        component={Link}
      />
      <Tab
        label="Resources"
        value={`/JJNews/ResourcesPage`}
        to={`/JJNews/Resources`}
        component={Link}
      />
    </Tabs>
  )
}
