import { formatPhoneNumber } from '@jjvgaming/player-payback-library'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { type LicensedEstablishment } from 'src/types/api'

interface BusinessDetailsProps {
  licensedEstablishment: LicensedEstablishment
}

export const BusinessDetailsMobile = ({
  licensedEstablishment,
}: BusinessDetailsProps) => {
  const theme = useTheme()

  return (
    <Box>
      <Grid container gap={2} display={'flex'} justifyContent={'space-between'}>
        <Grid item className="pt-3" xs={8}>
          <Typography variant="h3" pb={2}>
            Business Details
          </Typography>
        </Grid>
      </Grid>
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container className="flex flex-row flex-wrap" columnGap={24}>
          <Grid item sm={8}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Legal Company Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.name ? licensedEstablishment.name : '-'}
            </Typography>
            <Typography variant="body2" pb={1} color="text.secondary">
              DBA Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.standardName
                ? licensedEstablishment.standardName
                : '-'}
            </Typography>
          </Grid>
          <Grid item sm={8}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Short Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.shortName
                ? licensedEstablishment.shortName
                : '-'}
            </Typography>
            <Typography variant="body2" pb={1} color="text.secondary">
              Establishment Trade Type
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.type ? licensedEstablishment.type : '-'}
            </Typography>
            <Typography variant="body2" pb={1} color="text.secondary">
              License Number
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.licenseNumber
                ? `#${licensedEstablishment.licenseNumber}`
                : '-'}
            </Typography>
            <Grid item md={2}>
              <Typography variant="body2" pb={0.5} color="text.secondary">
                LE ID Number
              </Typography>
              <Typography variant="body1" pb={2}>
                {licensedEstablishment.id
                  ? `#${licensedEstablishment.id}`
                  : '-'}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="body2" pb={0.5} color="text.secondary">
                Phone Number
              </Typography>
              <Typography variant="body1" pb={2}>
                {formatPhoneNumber(licensedEstablishment.phoneNumber)}
              </Typography>
            </Grid>

            <Grid item md={2}>
              <Typography variant="body2" pb={0.5} color="text.secondary">
                Gaming Status
              </Typography>
              <Typography variant="body1" pb={2}>
                {licensedEstablishment.gamingStatus}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
