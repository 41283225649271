import { yupResolver } from '@hookform/resolvers/yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { colorPrimitives } from 'components/Theme'
import { FormFieldErrorMessage } from 'components/FormFieldMessage/FormFieldErrorMessage'
import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { type DefaultValues, FormProvider, useForm } from 'react-hook-form'
import { array, mixed, number, object, string } from 'yup'
import { useGetMe } from 'hooks/api/useGetMe'
import { useState } from 'react'
import { AttachFiles } from 'components/AttachFiles/AttachFiles'
import { useGetLesWithCaAndOrgAssociatedToUsersCa } from 'hooks/api/useGetLesWithCaAndOrgAssociatedToUsersCa'
import { ActivityButton } from 'components/ActivityButton'
import { usePostJJConnectUserContactUsEmail } from 'hooks/api/JJConnectUser/usePostJJConnectUserContactUsEmail'
import { useSnackbar } from 'stores/useSnackbar'
import { type components } from 'api/playerPayback/api'
import { type ContactUsEmailRequestType } from 'src/types/api'
import { useSetContactUsRequestType } from 'stores/useSetContactUsRequestType'

const ContactUsEmailSchema = object({
  location: number()
    .typeError('Location must be a number')
    .required()
    .min(1, 'Location is required'),
  requestType: mixed<ContactUsEmailRequestType>()
    .required()
    .oneOf(
      [
        'ScheduleMeeting',
        'NeedSignageOrFlags',
        'AddOrChangeEvent',
        'UpdateLicenses',
        'UpdateLocationHours',
        'ReportQuestions',
        'UpdateOfficers',
      ],
      'Request Type is required'
    ),
  emailBody: string().required('Email message is required.'),
  files: array(),
})

export interface JJConnectContactUsFields {
  location: number
  requestType: ContactUsEmailRequestType
  emailBody: string
  files?: Array<components['schemas']['FileDetail']> | []
}

export const defaultValues: DefaultValues<JJConnectContactUsFields> = {
  location: 0,
  requestType: 'None',
  emailBody: '',
  files: [],
}

export const ContactUsPage = () => {
  const theme = useTheme()
  const { zustandRequestType, resetRequestType } = useSetContactUsRequestType()
  const [selectedRequestType, setSelectedRequestType] = useState<string>('None')
  const [selectedLocation, setSelectedLocation] = useState<number>(0)
  const [resetKey, setResetKey] = useState(0)
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const meQuery = useGetMe()
  const currentUser = meQuery.data?.user

  const { licensedEstablishments } = useGetLesWithCaAndOrgAssociatedToUsersCa()

  const formMethods = useForm({
    resolver: yupResolver(ContactUsEmailSchema),
  })

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = formMethods

  const usePostJJConnectUserContactUsEmailMutation =
    usePostJJConnectUserContactUsEmail({
      onSuccess: () => {
        setSnackbarMessage('Email sent successfully.', 'success')
        setSelectedLocation(0)
        setSelectedRequestType('None')

        // Set resetKey to trigger re-render/reset form
        reset(defaultValues)
        resetRequestType()
        setResetKey((prevKey) => prevKey + 1)
      },
      onError: () => {
        setSnackbarMessage('There was an error sending the email.', 'error')
      },
    })

  const handleSubmitWrapper = handleSubmit(async (data) => {
    if (currentUser?.id) {
      usePostJJConnectUserContactUsEmailMutation.mutate({
        licensedEstablishmentUserId: currentUser?.id ?? 0,
        licensedEstablishmentId: data.location,
        requestType: data.requestType,
        body: data.emailBody,
        files: data.files ?? [],
      })
    } else {
      setSnackbarMessage('User ID is missing', 'error')
    }
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmitWrapper}>
        <Page
          header={
            <Box>
              <PageHeader
                title="Contact Us"
                isSecondary={true}
                headerBgColor="white"
              />
            </Box>
          }
        >
          <Box
            border={{ md: `1px solid ${theme.palette.grey[200]}` }}
            borderRadius={'5px'}
            p={3}
          >
            <Stack spacing={4}>
              <Stack direction={'column'} spacing={1}>
                <Box display="flex">
                  <Box width={'60px'}>Name:</Box>
                  <Box component="span" color={colorPrimitives.darkGray}>
                    {currentUser?.firstName} {currentUser?.lastName}
                  </Box>
                </Box>
                <Box display="flex">
                  <Box width={'60px'}>Email:</Box>
                  <Box component="span" color={colorPrimitives.darkGray}>
                    {currentUser?.email}
                  </Box>
                </Box>
              </Stack>

              <Stack direction={'column'} spacing={1}>
                <Typography mt={3} mb={1}>
                  Location:
                </Typography>
                <Select
                  {...register('location')}
                  displayEmpty
                  sx={{
                    width: '100%',
                    maxWidth: { xs: '100%', sm: '100%', md: '25%' },
                    minWidth: '250px',
                  }}
                  onChange={(event) =>
                    event.target.value === 0
                      ? setSelectedLocation(0)
                      : setSelectedLocation(Number(event.target.value))
                  }
                  value={selectedLocation}
                  defaultValue={defaultValues.location}
                  variant="outlined"
                  inputProps={{
                    'data-testid': 'location-select',
                    'aria-label': 'location-select',
                  }}
                  error={isPresent(errors.location)}
                >
                  <MenuItem disabled value={0}>
                    <span style={{ color: '#9e9e9e' }}>Select Location</span>
                  </MenuItem>
                  {licensedEstablishments.map((x) => {
                    return x.standardName === null ? (
                      <MenuItem key={x.id} value={0}>
                        {x.standardName}
                      </MenuItem>
                    ) : (
                      <MenuItem key={x.id} value={x.id}>
                        {x.standardName}
                      </MenuItem>
                    )
                  })}
                </Select>

                {errors.location?.message && (
                  <FormFieldErrorMessage message={errors.location.message} />
                )}
              </Stack>

              <Stack direction={'column'} spacing={1}>
                <Typography>Request Type:</Typography>
                <Select
                  inputProps={{
                    'data-testid': 'request-type-select',
                    'aria-label': 'request-type-select',
                  }}
                  {...register('requestType')}
                  displayEmpty
                  sx={{
                    width: '100%',
                    maxWidth: { xs: '100%', sm: '100%', md: '25%' },
                    minWidth: '250px',
                  }}
                  onChange={(event) => {
                    resetRequestType() // Reset Zustand request type
                    event.target.value === 'None'
                      ? setSelectedRequestType('None')
                      : setSelectedRequestType(event.target.value)
                  }}
                  value={zustandRequestType || selectedRequestType}
                  defaultValue={defaultValues.requestType}
                  variant="outlined"
                  error={isPresent(errors.requestType)}
                >
                  <MenuItem disabled value={'None'}>
                    <span style={{ color: '#9e9e9e' }}>
                      Select Request Type
                    </span>
                  </MenuItem>
                  <MenuItem value={'ScheduleMeeting'}>
                    Schedule Meeting with Account Manager
                  </MenuItem>
                  <MenuItem value={'NeedSignageOrFlags'}>
                    Need Signage/Flags
                  </MenuItem>
                  <MenuItem value={'AddOrChangeEvent'}>
                    Add/Change Specials/Events
                  </MenuItem>
                  <MenuItem value={'UpdateLicenses'}>
                    Update Gaming or Liquor Licenses
                  </MenuItem>
                  <MenuItem value={'UpdateLocationHours'}>
                    Update Location Hours
                  </MenuItem>
                  <MenuItem value={'ReportQuestions'}>
                    Report Questions
                  </MenuItem>
                  <MenuItem value={'UpdateOfficers'}>Update Officers</MenuItem>
                </Select>
                {errors.requestType?.message && (
                  <FormFieldErrorMessage message={errors.requestType.message} />
                )}
              </Stack>

              <Stack direction={'column'} spacing={1}>
                <Typography mt={3} mb={1}>
                  How can we help you?
                </Typography>
                <TextField
                  sx={{
                    width: '100%',
                    maxWidth: { xs: '100%', sm: '100%', md: '50%' },
                    minWidth: '250px',
                  }}
                  inputProps={{ maxLength: 1000 }}
                  multiline={true}
                  minRows={4}
                  placeholder="Please enter your message"
                  {...register('emailBody')}
                  error={isPresent(errors.emailBody)}
                />
                {errors.emailBody?.message && (
                  <FormFieldErrorMessage message={errors.emailBody.message} />
                )}
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                <ActivityButton
                  active={usePostJJConnectUserContactUsEmailMutation.isPending}
                  type="submit"
                  sx={{ width: '150px' }}
                  variant="contained"
                >
                  Send
                </ActivityButton>
                <AttachFiles
                  key={resetKey} // re-render/reset AttachFiles data
                  files={formMethods.watch('files')}
                  onFilesChange={(files) =>
                    formMethods.setValue('files', files)
                  }
                />
              </Stack>
            </Stack>
          </Box>
        </Page>
      </form>
    </FormProvider>
  )
}
