import { Box } from '@mui/material'
import type { GridColDef, GridEventListener } from '@mui/x-data-grid'
import { DataTable } from 'components/Shared/DataTable'
import { useLayout } from 'hooks/useLayout'
import { type LicensedEstablishmentUser } from 'src/types/api'
import { DeleteLicensedEstablishmentUserRenderCell } from './DeleteLicensedEstablishmentUserRenderCell'
import { formatDateTime } from 'utils/util'
import { useNavigate } from 'react-router-dom'

interface LEConnectUserListProps {
  users: LicensedEstablishmentUser[]
}

const defaultColumns = (isMobile: boolean): GridColDef[] => {
  return [
    {
      field: 'firstName',
      minWidth: 300,
      headerName: 'First Name',
      flex: 1,
      valueGetter: (params) => {
        return isMobile
          ? `${String(params.row.firstName)} ${String(params.row.lastName)}`
          : params.row.firstName
      },
    },
    {
      field: 'lastName',
      minWidth: 50,
      headerName: 'Last Name',
      flex: 1,
      valueGetter: (params) => {
        return isMobile ? '' : params.row.lastName
      },
    },
    {
      field: 'email',
      minWidth: 50,
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'type',
      minWidth: 50,
      headerName: 'Role',
      flex: 1,
    },
    {
      field: 'lastAccessed',
      headerName: 'Last Accessed',
      minWidth: 130,
      flex: 1,
      valueGetter: (params) =>
        params.row.licensedEstablishmentUserMetrics.length >= 1
          ? params.row.licensedEstablishmentUserMetrics[
              params.row.licensedEstablishmentUserMetrics.length - 1
            ].lastAccessed
          : null,
      valueFormatter: ({ value }) => formatDateTime(value),
    },
    {
      field: '__menu__',
      headerName: '',
      align: 'left',
      renderCell: (params) => {
        return <DeleteLicensedEstablishmentUserRenderCell params={params} />
      },
    },
  ]
}

export const LEConnectUserList = ({ users }: LEConnectUserListProps) => {
  const { isMobile } = useLayout()
  const navigate = useNavigate()

  const selectCell: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== '__menu__' && params.id !== null) {
      void navigate(
        `/LicensedEstablishmentUsers/LEConnectUserDetailsPage/${params.id}`
      )
    }
  }

  return (
    <Box paddingTop={{ xs: '20px', sm: '32px' }}>
      <DataTable
        columns={defaultColumns(isMobile)}
        rows={users}
        noDataMessage="No J&J Connect Users Found"
        sortFieldName="firstName"
        getRowClassName={() => 'text-[#2c2c27]'}
        onCellClick={selectCell}
      />
    </Box>
  )
}
