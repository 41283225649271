import { create } from 'zustand'

const initialState = {
  zustandRequestType: '',
}

interface SetContactUsRequestTypeState {
  zustandRequestType: string
  updateLicenses: () => void
  updateHours: () => void
  resetRequestType: () => void
}

export const useSetContactUsRequestType = create<SetContactUsRequestTypeState>(
  (set) => ({
    ...initialState,
    zustandRequestType: '',
    updateLicenses: () => {
      set({ zustandRequestType: 'UpdateLicenses' })
    },
    updateHours: () => {
      set({ zustandRequestType: 'UpdateLocationHours' })
    },
    resetRequestType: () => set(initialState),
  })
)
