import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { format } from 'date-fns'
import { useGetVgtSnapshotReport } from 'hooks/api/Reports/useGetVgtSnapshotReport'
import { VgtSnapshotChart } from './VgtSnapshotChart'
import { useSetLe } from 'stores/useSetLe'
import { GamingReportsHeader } from '../GamingReportsHeader'
import { WeekRangeSelect } from 'components/WeekRangeSelect'

export const VgtSnapshotPage = () => {
  const { passedLe } = useSetLe()
  const getDateOffset = (days: number) => {
    const date = new Date()
    date.setDate(date.getDate() + days - 1)
    return date
  }
  const [numDays, setNumDays] = useState<string>('28')
  const [startDate, setStartDate] = useState<Date>(getDateOffset(-28))
  const [endDate] = useState<Date>(getDateOffset(-1))

  const reportQuery = useGetVgtSnapshotReport({
    licensedEstablishmentId: Number(passedLe?.id),
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  const [displayType, setDisplayType] = useState<'$' | '%'>('$')

  const buttonStyle = (type: '$' | '%') => ({
    maxWidth: '40px',
    maxHeight: '40px',
    minWidth: '40px',
    minHeight: '40px',
    fontSize: '1.8rem',
    backgroundColor: displayType === type ? '#CC2027' : '#e0e0e0',
    color: displayType === type ? 'white' : 'black',
  })

  return (
    <GamingReportsHeader currentTab={'5'}>
      <WeekRangeSelect
        label={`Weeks of: ${formattedStartDate} - ${formattedEndDate}`}
        numDays={numDays}
        setStartDate={setStartDate}
        dateOffset={getDateOffset}
        setNumDays={setNumDays}
        displayWeeks={[1, 2, 3, 4, 5, 6]}
      >
        <Stack spacing={4}>
          <Grid container spacing={1} alignItems="center">
            <Box className="py-1 flex flex-row gap-4 xs:justify-between">
              <Button
                variant="contained"
                onClick={() => setDisplayType('$')}
                style={buttonStyle('$')}
                title="Location Total"
              >
                $
              </Button>
              <Button
                variant="contained"
                onClick={() => setDisplayType('%')}
                style={buttonStyle('%')}
                title="Percentage of Location Total"
              >
                %
              </Button>
            </Box>
          </Grid>

          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {reportQuery.data &&
            (reportQuery.data.metrics.length === 0 ? (
              <Typography variant="h4"></Typography>
            ) : (
              <VgtSnapshotChart
                data={reportQuery.data.metrics}
                displayType={displayType}
              />
            ))}
        </Stack>
      </WeekRangeSelect>
    </GamingReportsHeader>
  )
}
