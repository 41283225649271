import Box from '@mui/material/Box'
import { type Promotion } from 'src/types/api.ts'
import { formatDateTime } from 'utils/util'
import { type RequiredId } from 'utils/typeUtils'
import { SectionHeader } from 'components/SectionHeader'
import { Grid, Typography, useTheme } from '@mui/material'

interface PromotionDetailsProps {
  promotion: RequiredId<Promotion>
}

export const PromotionDetailsDesktop = ({
  promotion,
}: PromotionDetailsProps) => {
  const theme = useTheme()

  const promotionType = () => {
    if (promotion.type === 'Global') {
      return `J&J Promo`
    } else if (
      promotion.licensedEstablishments &&
      promotion.licensedEstablishments.length > 0
    ) {
      return `Location Specific`
    } else if (promotion.corporateAccount) {
      return `Corporate Account Specific`
    } else if (promotion.organization) {
      return `Organization Specific`
    } else return '-'
  }

  return (
    <Box className={'pt-4'}>
      <Box className="pt-4">
        <Box>
          <SectionHeader title="Special & Event Details" showButton={false} />
          <Box
            border={{ md: `1px solid ${theme.palette.grey[200]}` }}
            borderRadius={'5px'}
            p={{ xs: 0, sm: 3 }}
          >
            <Grid container className="flex flex-row flex-wrap" columnGap={15}>
              <Grid item md={2}>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Special & Event Name
                </Typography>
                <Typography variant="body1" pb={2}>
                  {promotion.title}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Start Date
                </Typography>
                <Typography variant="body1" pb={2}>
                  {promotion.startDate
                    ? formatDateTime(promotion.startDate, 'P p')
                    : 'None'}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography variant="body2" pb={1} color="text.secondary">
                  End Date
                </Typography>
                <Typography variant="body1" pb={2}>
                  {promotion.endDate
                    ? formatDateTime(promotion.endDate, 'P p')
                    : 'None'}
                </Typography>
              </Grid>
            </Grid>

            <Grid container className="flex flex-row flex-wrap" columnGap={15}>
              <Grid item md={2}>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Special & Event Type
                </Typography>
                <Typography variant="body1" pb={2}>
                  {promotionType()}
                </Typography>
              </Grid>
              {promotion.type === 'Location' && (
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    LE Name
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {`${promotion.licensedEstablishments?.length} Location(s)`}
                  </Typography>
                </Grid>
              )}
              {promotion.type === 'CorporateAccount' && (
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Corporate Account
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {promotion.corporateAccount?.name}
                  </Typography>
                </Grid>
              )}
              {promotion.type === 'Organization' && (
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Organization
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {promotion.organization?.name}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container className="flex flex-row flex-wrap" columnGap={15}>
              <Grid item md={8}>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Description
                </Typography>
                <Typography variant="body1" pb={2}>
                  {promotion.description ?? ''}
                </Typography>
              </Grid>
            </Grid>

            <Grid container className="flex flex-row flex-wrap" columnGap={15}>
              <Grid item md={8}>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Link
                </Typography>
                <Typography variant="body1">
                  {promotion.linkLabel ?? '-'}
                </Typography>
                <Typography variant="body1">
                  {promotion.linkURL ?? ''}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
