import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  Zoom,
  MenuItem,
  Select,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { colorPrimitives } from 'components/Theme'
import AccountManagerIcon from 'assets/AccountManagerIcon.svg'
import { isNil } from 'lodash'
import { useState, useEffect } from 'react'
import { useGetLicensedEstablishmentByCorporateAccount } from 'hooks/api/useGetLicensedEstablishmentByCorporateAccount'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'

export const AccountManager = () => {
  const selectedCorporateAccount = useCurrentCorporateAccountStore()
  const [corporateAccountId, setCorporateAccountId] = useState(
    selectedCorporateAccount.currentCorporateAccountId ?? -1
  )
  const [showAccountManager, setShowAccountManager] = useState(false)
  const [locationsShareAccountManager, setLocationsShareAccountManager] =
    useState(false)
  const [selectedLE, setSelectedLE] = useState<number>(-1)
  const [accountManagerName, setAccountManagerName] = useState<
    string | null | undefined
  >('')
  const [accountManagerEmail, setAccountManagerEmail] = useState<
    string | null | undefined
  >('')
  const [accountManagerPhoneNumber, setAccountManagerPhoneNumber] = useState<
    string | null | undefined
  >('')
  const licensedEstablishmentsQuery =
    useGetLicensedEstablishmentByCorporateAccount({
      corporateAccountId,
    })

  useEffect(() => {
    if (selectedCorporateAccount.currentCorporateAccountId) {
      setCorporateAccountId(selectedCorporateAccount.currentCorporateAccountId)
    }
  }, [selectedCorporateAccount])

  useEffect(() => {
    if (selectedLE !== -1 && licensedEstablishmentsQuery.data) {
      const le = licensedEstablishmentsQuery.data.find(
        (le) => le.id === selectedLE
      )
      if (le !== null && le !== undefined) {
        setAccountManagerName(le.accountManagerName)
        setAccountManagerEmail(le?.accountManagerEmail)
        setAccountManagerPhoneNumber(le?.accountManagerPhoneNumber)
      }
    }
  }, [selectedLE])

  useEffect(() => {
    if (
      licensedEstablishmentsQuery.data &&
      licensedEstablishmentsQuery.data.length > 0
    ) {
      setSelectedLE(licensedEstablishmentsQuery.data[0].id)
      const firstAccountManagerName =
        licensedEstablishmentsQuery.data[0].accountManagerName
      setLocationsShareAccountManager(
        licensedEstablishmentsQuery.data.every(
          (le) => le.accountManagerName === firstAccountManagerName
        )
      )
    } else {
      setSelectedLE(-1)
      setAccountManagerName('')
      setAccountManagerEmail('')
      setAccountManagerPhoneNumber('')
      setLocationsShareAccountManager(true)
    }
  }, [licensedEstablishmentsQuery.data])

  if (licensedEstablishmentsQuery.isPending) {
    return <CircularProgress />
  }

  if (licensedEstablishmentsQuery.isError) {
    return <p>An error occurred.</p>
  }

  const isNotNull = (s: string | null | undefined): s is string => {
    return !isNil(s) && s !== ''
  }

  const boxClassName = `h-[80px] w-full max-w-[375px] md:max-w-[475px] fixed right-7 rounded-l rounded-r-xl md:rounded-r-full text-white p-6 flex justify-start items-center ${
    !locationsShareAccountManager
      ? 'md:h-[140px] bottom-2'
      : 'md:h-[100px] bottom-5'
  }`

  return (
    <>
      <Zoom in={showAccountManager}>
        <Box className={boxClassName} bgcolor={colorPrimitives.darkRed}>
          <Stack spacing={1}>
            <Stack justifyContent={'center'}>
              {!locationsShareAccountManager &&
              licensedEstablishmentsQuery.data.length > 1 ? (
                <FormControl size="small">
                  <Select
                    onChange={(event) => {
                      setSelectedLE(Number(event.target.value))
                    }}
                    value={selectedLE}
                    sx={{
                      width: '300px',
                      color: 'white', // Font color
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color on hover
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color when focused
                      },
                      '& .MuiSvgIcon-root': {
                        color: 'white', // Arrow color
                      },
                    }}
                  >
                    {licensedEstablishmentsQuery.data.map((le) => {
                      return (
                        <MenuItem key={le.id} value={le.id}>
                          {le.name} (#{le.licenseNumber})
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              ) : null}
            </Stack>
            {isNotNull(accountManagerName) &&
            isNotNull(accountManagerEmail) &&
            isNotNull(accountManagerPhoneNumber) ? (
              <>
                <Stack justifyContent={'center'}>
                  <Typography
                    lineHeight={'18px'}
                    variant="subtitle-2"
                    fontWeight={700}
                  >
                    {accountManagerName}
                  </Typography>
                  <Typography lineHeight={'18px'} variant="label-form">
                    Account Manager
                  </Typography>
                </Stack>
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems={'center'}
                  className="whitespace-nowrap"
                >
                  <Typography variant="body-2">
                    {accountManagerPhoneNumber?.replace(/ +/g, '')}
                  </Typography>
                  <Typography>|</Typography>
                  <Typography variant="body-2">
                    {accountManagerEmail?.replace(/ +/g, '')}
                  </Typography>
                </Stack>
              </>
            ) : (
              <Typography>No Account Manager assigned</Typography>
            )}
          </Stack>
        </Box>
      </Zoom>
      <Box className="fixed bottom-5 right-7 rounded-full text-white flex justify-end items-center">
        <IconButton
          aria-label="accountmanagerbutton"
          focusRipple
          sx={{
            backgroundColor: colorPrimitives.redGaming,
            boxShadow: 10,
          }}
          className={`w-[80px] h-[80px] md:w-[100px] md:h-[100px] ${
            !locationsShareAccountManager ? 'bottom-2' : 'bottom-0'
          }`}
          onClick={() => {
            setShowAccountManager((prev) => !prev)
          }}
        >
          <img src={AccountManagerIcon} alt="Account Manager Icon" />
        </IconButton>
      </Box>
    </>
  )
}
