import {
  Alert,
  Box,
  Container,
  CssBaseline,
  Snackbar,
  Stack,
} from '@mui/material'
import {
  NAVIGATION_BAR_WIDTH,
  NavigationBar,
} from 'components/NavigationBar/NavigationBar'
import { APP_BAR_HEIGHT, AppBar } from 'components/AppBar/AppBar'
import { Footer, footerMaxHeight } from 'components/Footer/Footer'
import { useSnackbar } from 'stores/useSnackbar'

export const Page = (props: {
  children: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  px?: string | number
  pt?: string | number
  pb?: string | number
}) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const snackbarMessage = useSnackbar((state) => state.message)
  const snackbarSeverity = useSnackbar((state) => state.severity)
  const snackbarVisible = useSnackbar((state) => state.visible)
  return (
    <>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>
        <AppBar />
      </Container>
      <Stack
        direction="row"
        pb={{
          xs: `${props.footer ? footerMaxHeight : 0 + 21}px`,
          sm: `${props.footer ? footerMaxHeight : 0 + 32}px`,
        }}
      >
        <NavigationBar />
        <Box
          sx={{
            height: `calc(100vh - 65px)`,
            flex: 1,
            position: 'relative',
            top: `${APP_BAR_HEIGHT}px`,
            ml: { xs: 0, sm: `${NAVIGATION_BAR_WIDTH}px` },
            pb: { xs: '21px', sm: '14px' },
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {props.header}
          <Box
            px={props.px ?? { xs: '16px', sm: '48px' }}
            pt={props.pt ?? { xs: '21px', sm: 4 }}
            pb={
              props.pb ?? {
                xs: `${props.footer ? footerMaxHeight : 0 + 21}px`,
                sm: `${props.footer ? footerMaxHeight : 0 + 32}px`,
              }
            }
          >
            {props.children}
          </Box>
        </Box>
        <Snackbar
          open={snackbarVisible}
          autoHideDuration={6000}
          onClose={() => {
            setSnackbarMessage(null)
          }}
          message={snackbarMessage}
        >
          <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
        </Snackbar>
        {props.footer && (
          <Footer navigationBarWidth={NAVIGATION_BAR_WIDTH}>
            {props.footer}
          </Footer>
        )}
      </Stack>
    </>
  )
}
