import { Box, Button, Typography } from '@mui/material'
import { type GridColDef } from '@mui/x-data-grid'
import { DataTable } from 'components/Shared/DataTable'
import { useFormContext } from 'react-hook-form'
import { type LeUserLocationsSchema } from './EditAssociatedAccountsModal'
import { type InferType } from 'yup'
import { concat, isNil, uniq } from 'lodash'
import { isPresent } from '@jjvgaming/player-payback-library'
import React from 'react'
import { type CorporateAccount } from 'src/types/api'

export const AssociatedCorporateAccounts = ({
  availableCorporateAccounts,
}: {
  availableCorporateAccounts: CorporateAccount[]
}) => {
  return (
    <DataTable
      columns={Columns}
      rows={availableCorporateAccounts}
      noDataMessage="No Associated Corporate Accounts"
    />
  )
}

const Columns: GridColDef[] = [
  { field: 'name', headerName: 'Account Name', flex: 3 },
  { field: 'id', headerName: 'ID', flex: 1 },
  {
    headerName: '',
    flex: 1,
    field: 'actions',
    minWidth: 175,
    align: 'right',
    renderCell: (params) => {
      return <RemoveAccessButton corporateAccountId={Number(params.id)} />
    },
  },
]

const RemoveAccessButton = ({
  corporateAccountId: removeCorporateAccountId,
}: {
  corporateAccountId: number
}) => {
  const { watch, setValue } =
    useFormContext<InferType<typeof LeUserLocationsSchema>>()
  const removeCorporateAccountIds = watch('removeCorporateAccountIds')
  const alreadyRemoved = React.useMemo(() => {
    return (
      isPresent(removeCorporateAccountIds) &&
      removeCorporateAccountIds.includes(removeCorporateAccountId)
    )
  }, [removeCorporateAccountIds])

  if (alreadyRemoved) {
    return (
      <Box className="px-3 pt-1 mx-4 bg-gray-de">
        <Typography
          variant="label-CTA"
          className="tracking-wider text-black uppercase"
        >
          Removed
        </Typography>
      </Box>
    )
  }

  return (
    <Button
      variant="text"
      onClick={() => {
        if (isNil(removeCorporateAccountIds)) {
          return
        }

        setValue(
          'removeCorporateAccountIds',
          uniq(concat(removeCorporateAccountIds, removeCorporateAccountId))
        )
      }}
    >
      Remove Access
    </Button>
  )
}
