import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetLEUserById = ({ userId }: { userId: number }) => {
  const get = useTypedApiClient({
    path: '/le-connect/licensed-establishments/users/{id}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/le-connect/licensed-establishments/users/{id}', userId],
    queryFn: async () => {
      const { data } = await get({ id: userId })
      return data
    },
  })
}
