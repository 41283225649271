import { Box, Divider, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowLeft from 'assets/arrow-left.svg?react'
import { isNil } from 'lodash'
import { grey } from '@mui/material/colors'

export const PageHeader = ({
  children,
  tabs,
  title,
  identifier,
  subtitle,
  customSubTitle,
  actionButton,
  backText,
  backPath,
  isSecondary,
  hasDivider,
  status,
  headerBgColor,
}: {
  children?: React.ReactNode
  tabs?: React.ReactNode
  title: string
  identifier?: string
  subtitle?: string
  customSubTitle?: React.ReactNode
  actionButton?: React.ReactNode
  backText?: string
  backPath?: string
  isSecondary?: boolean
  hasDivider?: boolean
  status?: React.ReactNode
  headerBgColor?: string
}) => {
  return (
    <Box>
      <Box
        pt={'30px'}
        pb={hasDivider ? '12px' : '0px'}
        px={{ xs: '16px', sm: '41px' }}
        bgcolor={headerBgColor ?? (isSecondary === true ? grey[50] : 'white')}
        borderBottom={
          hasDivider
            ? '1px solid var(--outlines-Outline-LIght, rgba(41, 43, 38, 0.10))'
            : ''
        }
      >
        {!isNil(backPath) && (
          <Link to={backPath} id="back-link">
            <Box
              display={'flex'}
              flexDirection="row"
              alignItems="center"
              gap={1}
              mb={3}
              color="text.secondary"
            >
              <ArrowLeft />
              <Typography pt={0.5}>Back to {backText}</Typography>
            </Box>
          </Link>
        )}
        <Box className="flex flex-col w-full space-y-6 sm:space-y-0 sm:flex-row sm:justify-between sm:items-start">
          <Box>
            <Typography variant="h1">{title}</Typography>
            {!isNil(identifier) ? (
              <Typography variant="body-1"> ID: #{identifier}</Typography>
            ) : (
              ''
            )}
            <Box className="flex items-start lg:items-center w-full flex-col lg:flex-row gap-4">
              {status}
              <Box
                className="px-3 h-4 hidden lg:flex"
                sx={{ ...(!status && { display: 'none' }) }}
              >
                <Divider
                  sx={{ ...(!subtitle && { display: 'none' }) }}
                  orientation="vertical"
                />
              </Box>
              {!isNil(subtitle) && isNil(customSubTitle) && (
                <Typography
                  sx={{ fontSize: '16px' }}
                  variant="body-3"
                  className="whitespace-pre-line"
                  pt={0.5}
                >
                  {subtitle}
                </Typography>
              )}
              {customSubTitle}
            </Box>
            <Typography variant="body-1">{children}</Typography>
          </Box>
          <Box mt={{ xs: '24px', sm: '0' }}>{actionButton}</Box>
        </Box>
        {tabs && <Box pt={2}>{tabs}</Box>}
      </Box>
    </Box>
  )
}
