import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { SimpleImageCard } from 'components/ImageCard/SimpleImageCard'
import { type Newsletter } from 'src/types/api'

export const NewsletterCard = ({ newsletter }: { newsletter: Newsletter }) => {
  return (
    <Card className="mb-10">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <SimpleImageCard
              imageSource={newsletter.publicImageUrl}
              sx={{ maxWidth: '200px', maxHeight: '200px' }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h2" sx={{ paddingBottom: '15px' }}>
              {newsletter.title}
            </Typography>
            <Button
              variant="contained"
              component="a"
              href={newsletter.publicFileUrl ?? ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography>Read Newsletter</Typography>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
