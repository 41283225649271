import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { formatDateTime } from 'utils/util'
import { type News } from 'src/types/api'

export const NewsCard = ({ news }: { news: News }) => {
  return (
    <Card
      className="mb-10"
      sx={{
        width: '250px',
        height: '250px',
        display: 'block',
        textDecoration: 'none',
      }}
      component="a"
      href={news.url ?? ''}
      target="_blank"
      rel="noopener noreferrer"
    >
      <CardContent>
        <CardMedia
          sx={{ height: 100 }}
          image={news.image ?? ''}
          title={news.title ?? ''}
        />
        <Grid container spacing={2}>
          <Grid item>
            <Typography sx={{ paddingY: '15px' }}>{news.title}</Typography>
            <Typography variant="body2">
              {formatDateTime(news.date, 'MMM dd yyyy')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
